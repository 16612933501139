<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="procesosImportacion"
      class="elevation-1"
      :search="search"
      item-key="procesoImportacionId"
    >
      <template v-slot:[`item.anulado`]="{ item }">
        <v-icon v-if="item.anulado" small color="primary">
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" align="end">
              <v-btn
                color="primary"
                class="to-right"
                @click="toggleModalNuevoProcesoImportacion()"
              >
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              :disabled="item.anulado"
              @click="
                toggleModalSeeProcesoImportacion(item.procesoImportacionId)
              "
            >
              {{ seeIcon }}
            </v-icon>
          </template>
          <span>Ver detalle</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              :disabled="item.anulado"
              @click="anularProceso(item.procesoImportacionId)"
            >
              {{ anularIcon }}
            </v-icon>
          </template>
          <span>Anular proceso</span>
        </v-tooltip>
      </template></v-data-table
    >
    <v-dialog
      v-model="modalVerDetalleProcesoImportancion"
      v-if="modalVerDetalleProcesoImportancion"
      @keydown.esc="toggleModalSeeProcesoImportacion"
      max-width="90%"
      persistent
    >
      <VerDetalleProcesoImportacion
        @toggleModalSeeProcesoImportacion="toggleModalSeeProcesoImportacion"
        :procesoImportacionId="procesoImportacionId"
      ></VerDetalleProcesoImportacion>
    </v-dialog>
    <v-dialog
      v-model="modalNuevoProcesoImportacion"
      v-if="modalNuevoProcesoImportacion"
      @keydown.esc="toggleModalNuevoProcesoImportacion"
      max-width="70%"
      persistent
    >
      <NuevoProcesoImportacion
        @toggleModalNuevoProcesoImportacion="toggleModalNuevoProcesoImportacion"
      ></NuevoProcesoImportacion>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmAnularProceso()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import VerDetalleProcesoImportacion from "@/components/modules/cuotas/devengamientos/VerDetalleProcesoImportacion.vue";
import NuevoProcesoImportacion from "@/components/modules/cuotas/devengamientos/NuevoProcesoImportacion.vue";

export default {
  name: "ProcesosImportacion",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    VerDetalleProcesoImportacion,
    NuevoProcesoImportacion
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setProcesosImportacion();
  },
  data() {
    return {
      routeToGo: "ConceptosADevengar",
      title: "Procesos de importación",
      search: "",
      titleDelete: "¿Desea anular el proceso?",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      anularIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      expanded: [],
      procesosImportacion: [],
      headers: [
        {
          text: "Fecha alta",
          align: "start",
          value: "fechaAlta",
          sortable: false
        },
        {
          text: "Usuario alta",
          align: "start",
          value: "usuarioAlta",
          sortable: false
        },
        {
          text: "Nombre archivo",
          align: "start",
          value: "rutaArchivo",
          sortable: false
        },
        {
          text: "Anulado",
          align: "center",
          value: "anulado",
          sortable: false
        },
        {
          text: "Fecha anulado",
          align: "start",
          value: "fechaAnula",
          sortable: false
        },
        {
          text: "Usuario anulado",
          align: "start",
          value: "usuarioAnula",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      procesoImportacionId: null,
      modalVerDetalleProcesoImportancion: false,
      showDeleteModal: false,
      modalNuevoProcesoImportacion: false
    };
  },
  methods: {
    ...mapActions({
      getProcesosImportacionXUnicaVez:
        "devengamientos/getProcesosImportacionXUnicaVez",
      anularProcesoImportacionXUnicaVez:
        "devengamientos/anularProcesoImportacionXUnicaVez",
      setAlert: "user/setAlert"
    }),
    async setProcesosImportacion() {
      const procesos = await this.getProcesosImportacionXUnicaVez();
      this.procesosImportacion = procesos;
    },
    toggleModalSeeProcesoImportacion(id) {
      this.modalVerDetalleProcesoImportancion = !this
        .modalVerDetalleProcesoImportancion;
      this.procesoImportacionId = id;
    },
    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.setProcesosImportacion();
    },
    anularProceso(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmAnularProceso() {
      const response = await this.anularProcesoImportacionXUnicaVez({
        procesoImportacionId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Anulado con éxito." });
        this.setProcesosImportacion();
      }
    }
  }
};
</script>

<style></style>
