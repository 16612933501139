<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        <v-col cols="8">Nuevo proceso de importación</v-col>
      </v-card-title>
      <v-card-text>
        <!-- Proceso de importación -->
        <v-row cols="12" class="py-0">
          <v-col cols="7">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              id="fileUpload"
              type="file"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2" class="py-0">
            <v-btn
              color="primary"
              class="no-upper-case to-right"
              outlined
              @click="openModalSeeFormatoArchivo()"
            >
              Ver formato
            </v-btn></v-col
          >
          <v-btn outlined @click="closeModalExcel()"> Cerrar </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headers"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";

export default {
  name: "NuevoProcesoImportacion",
  directives: { mask },
  components: { FormatoArchivoDialog },
  data() {
    return {
      rules: rules,
      loading: false,
      loadingProcesarBtn: false,
      entFinId: null,
      comprobantesAGenerar: [],
      excelData: [],
      modalExportarExcel: false,
      archivoEnProceso: false,
      file: null,
      isFormSIROValid: false,
      fechaDesdeSIRO: null,
      fechaDesdeTextFieldSIRO: null,
      menuDesdeSIRO: false,
      fechaHastaSIRO: null,
      fechaHastaTextFieldSIRO: null,
      menuHastaSIRO: false,
      fileProcesoImportacion: null,
      registrosError: [],
      fileName: null,
      formatoTitle: "Formato de archivo",
      headers: [],
      datosFormatoArchivo: [],
      showSeeFormatModal: false,
      dataToExport: {
        formatoName: "Proceso importacion modelo",
        data: [
          {
            ["Agente"]: "170300 (Campo requerido)",
            ["Periodo"]: "202209 (Campo requerido)",
            ["Concepto"]: "Percepción de IIBB (Campo requerido)",
            ["Importe"]: "1702.25 (Campo requerido)",
            ["Grupo familiar"]: "(Campo opcional)",
            ["Periodo a devengar"]: "(Campo opcional)"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions({
      getFormatoProcesoImportacionXUnicaVez:
        "devengamientos/getFormatoProcesoImportacionXUnicaVez",
      saveProcesoImportacionXUnicaVez:
        "devengamientos/saveProcesoImportacionXUnicaVez",
      setAlert: "user/setAlert"
    }),
    closeModalExcel() {
      this.$emit("toggleModalNuevoProcesoImportacion");
    },

    async importExcel() {
      if (this.file) {
        try {
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.fileName = this.file.name;
            let excelDataTranslated = [];
            this.excelData[0].filas.forEach(x => {
              excelDataTranslated.push({
                agenteCuentaId: x["Agente"],
                periodo: x["Periodo"],
                concepto: x["Concepto"],
                importe: x["Importe"],
                benGrId: x["Grupo familiar"],
                periodoADevengar: x["Periodo a devengar"]
              });
            });
            this.saveProcesoImportacion(excelDataTranslated);
          } else {
            setTimeout(this.importExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loading = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    async saveProcesoImportacion(datosProceso) {
      const data = {
        rutaArchivo: this.fileName,
        datosProcesoImportacion: datosProceso
      };
      const response = await this.saveProcesoImportacionXUnicaVez(data);
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          this.modalExportarExcel = true;
          this.registrosError = response.data.data;
        } else {
          this.setAlert({ type: "success", message: "Procesado con éxito." });
          this.closeModalExcel();
        }
      }
    },
    getArchivo(rowsToIgnore) {
      // si el archivoEnProceso es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(this.file, rowsToIgnore);
        }
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    exportExcelError() {
      let result = [];
      this.registrosError.forEach(x =>
        result.push({
          ["Agente"]: x.agenteCuentaId,
          ["Periodo"]: x.periodo,
          ["Concepto"]: x.concepto,
          ["Importe"]: x.importe,
          ["Grupo familiar"]: x.benGrId,
          ["Periodo a devengar"]: x.periodoADevengar,
          ["Error"]: x.error
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Errores registros");
      this.closeModalExcel();
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.getFormatoProcesoImportacionXUnicaVez();
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        }
      ];
      this.showSeeFormatModal = true;
    }
  }
};
</script>
