var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.procesosImportacion,"search":_vm.search,"item-key":"procesoImportacionId"},scopedSlots:_vm._u([{key:"item.anulado",fn:function(ref){
var item = ref.item;
return [(item.anulado)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleModalNuevoProcesoImportacion()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.anulado},on:{"click":function($event){return _vm.toggleModalSeeProcesoImportacion(item.procesoImportacionId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.anulado},on:{"click":function($event){return _vm.anularProceso(item.procesoImportacionId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])])]}}],null,true)}),(_vm.modalVerDetalleProcesoImportancion)?_c('v-dialog',{attrs:{"max-width":"90%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalSeeProcesoImportacion($event)}},model:{value:(_vm.modalVerDetalleProcesoImportancion),callback:function ($$v) {_vm.modalVerDetalleProcesoImportancion=$$v},expression:"modalVerDetalleProcesoImportancion"}},[_c('VerDetalleProcesoImportacion',{attrs:{"procesoImportacionId":_vm.procesoImportacionId},on:{"toggleModalSeeProcesoImportacion":_vm.toggleModalSeeProcesoImportacion}})],1):_vm._e(),(_vm.modalNuevoProcesoImportacion)?_c('v-dialog',{attrs:{"max-width":"70%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalNuevoProcesoImportacion($event)}},model:{value:(_vm.modalNuevoProcesoImportacion),callback:function ($$v) {_vm.modalNuevoProcesoImportacion=$$v},expression:"modalNuevoProcesoImportacion"}},[_c('NuevoProcesoImportacion',{on:{"toggleModalNuevoProcesoImportacion":_vm.toggleModalNuevoProcesoImportacion}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"confirmButtonText":'Anular',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmAnularProceso()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }